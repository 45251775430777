<template>
  <div class="chell">

  <!--Normal Shifts-->
    <div v-if="data.state !== 'leave' &&data.state !== 'un-availability' && data.state !== 'cancel'" class="template-wrapper" v-bind:class="{ borderBind: data.state=== 'draft', borderBindBooked: data.state=== 'draft'&& data.user_id>0 }" :style="{background:data.applications_count !==0 ?'#EFA12C':data.color }">
      <div class="time float-left w-75 " style=" width: 50%; padding-top: 0px" >
        {{momentFormat(data.StartTime, 'HH:mm') +' - ' }}
        {{ momentFormat(data.EndTime,'HH:mm')}}
        <!-- <span class="ml-1" style="font-size: x-small" v-if="data.break_minutes !==0 && data.break_minutes !== null">({{data.break_minutes}} m)</span> -->
      </div>
      <div class="text-white font-weight-bold font-weight-700 float-right" style="border-radius: 8px;" v-if="data.applications_count || data.receivedSwapRequests" >
          <span v-if="data.applications_count !==0" class="black-badge" >
            <feather-icon icon="UserIcon" /> 
            <span>{{data.applications_count}}</span>
          </span>
          <span v-if="data.receivedSwapRequests && data.receivedSwapRequests.length > 0" class="black-badge">
            <span >
              <feather-icon icon="RepeatIcon" />
            </span>
          </span>
      </div>
      <div v-if="data.vacant_job_roles" class="w-50 d-flex" style="">
        <div class="float-left" v-if="data.vacant_job_roles.length===1">{{data.vacant_job_roles[0].job_role.name}}</div>
        <div class="float-left" v-else-if="data.vacant_job_roles.length>1">{{data.vacant_job_roles[0].job_role.name}}  ...+{{data.vacant_job_roles.length -1}}</div>
      </div>
      <div>
        <div class="float-left w-50 white-template" style="margin-bottom:2px" v-if="data.role_id && data.role_id[0]">
          <div class="white-template-content">
            <div class="" v-if="data.role_id.length===1">{{data.role_id[0].label}}</div>
            <div class="" v-else-if="data.role_id.length>1">{{data.role_id[0].label}}...+{{data.role_id.length -1}}</div>
          </div>
        </div>
      </div>
      <br>
      <div v-if="data.is_pcn" class="w-50 d-flex" style="" >{{data.pcn.name}}</div>
    </div>
  <!--cancel shifts-->
    <div v-if="data.state === 'cancel'" class="template-wrapper-cancel" v-bind:class="{ borderBind: data.state=== 'draft' }"
         :style="{background:data.applications_count !==0 ?'#EFA12C':data.color }">
      <div class="time"  >
        <del>{{momentFormat(data.StartTime, 'HH:mm') +' - ' }}</del>
        <del>{{ momentFormat(data.EndTime,'HH:mm')}}</del>
        <del>
          <span class="ml-1" style="font-size: x-small" v-if="data.break_minutes !==0 && data.break_minutes !== null">({{data.break_minutes}} m)</span>
        </del>
      </div>
        <div>
       <span style="font-size: x-small" v-if="data.break_minutes !==0 && data.break_minutes !== null"> Cancelled by {{data.user.first_name}} {{data.user.last_name}}</span>
    </div>
      <div class="w-100" style="width: 75%">
        <div v-if="data.vacant_job_roles" class="w-50 d-flex" style="">
        <div class="float-left" v-if="data.vacant_job_roles.length===1">{{data.vacant_job_roles[0].job_role.name}}</div>
        <div class="float-left" v-else-if="data.vacant_job_roles.length>1">{{data.vacant_job_roles[0].job_role.name}}  ...+{{data.vacant_job_roles.length -1}}</div>
      </div>
      <div>
        <div class="float-left w-50 white-template" style="" v-if="data.role_id && data.role_id[0]">
          <div class="white-template-content">
           <del  v-if="data.role_id.length===1"><div class="">{{data.role_id[0].label}}</div></del> 
            <del v-else-if="data.role_id.length>1"><div class="" >{{data.role_id[0].label}}...+{{data.role_id.length -1}}</div></del>
          </div>
        </div>
      </div>
        <br>
        <div v-if="data.is_pcn" style="margin-top: 2px; margin-left: 2px"><del>{{data.pcn.name}}</del></div>
      </div>
      <div v-if="data.vacant_job_roles">
        <div class="float-left" v-if="data.vacant_job_roles.length===1">{{data.vacant_job_roles[0].job_role.name}}</div>
        <div class="float-left" v-else-if="data.vacant_job_roles.length>1">{{data.vacant_job_roles[0].job_role.name}}  ...+{{data.vacant_job_roles.length -1}}</div>
      </div>
      <div class="w-25" style="width: 25%">
        <div style="margin-top:4px; background-color: black; padding:2px; width: 57px;border-radius: 8px" v-if="data.applications_count !==0" class="text-white font-weight-bold font-weight-700">
          <span class="p-1"><feather-icon icon="UserIcon" class=""  /> <span>{{data.applications_count}}</span></span>
        </div>
      </div>
    </div>

  <!--User Leave-->
    <div v-if="data.state=== 'leave'" class="template-wrapper" >
      <div class="float-left " >
        <svg fill="none" height="15" viewBox="0 0 12 12" width="15" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M9 4H9.5C10.0304 4 10.5391 4.21071 10.9142 4.58579C11.2893 4.96086 11.5 5.46957 11.5 6C11.5 6.53043 11.2893 7.03914 10.9142 7.41421C10.5391 7.78929 10.0304 8 9.5 8H9"
              stroke="#1B9AAA" stroke-linecap="round" stroke-linejoin="round"/>
          <path
              d="M1 4H9V8.5C9 9.03043 8.78929 9.53914 8.41421 9.91421C8.03914 10.2893 7.53043 10.5 7 10.5H3C2.46957 10.5 1.96086 10.2893 1.58579 9.91421C1.21071 9.53914 1 9.03043 1 8.5V4Z"
              stroke="#1B9AAA" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M3 0.5V2" stroke="#1B9AAA" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M5 0.5V2" stroke="#1B9AAA" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M7 0.5V2" stroke="#1B9AAA" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>

      <p class=" ml-2 text-dark" v-if="data.is_day">Full Day</p>
      <p  v-if="data.state=== 'leave' && !data.is_day" class=" ml-2 text-dark">{{momentFormat(data.StartTime, 'HH:mm') +' - '+ momentFormat(data.EndTime,'HH:mm')}}</p>
      <p class="text-dark">{{data.reason}}</p>
    </div>

  <!--User Un Availability-->
    <div v-if="data.state === 'un-availability'" class="template-wrapper" >
      <span class="  text-dark">User unavailable</span>
      <p  class="  text-dark" >{{momentFormat(data.StartTime, 'HH:mm') +' - '+ momentFormat(data.EndTime,'HH:mm')}}</p>
    </div>
  </div>

</template>

<script>

import MomentMixin from '@/mixins/MomentMixin'
import ErrorMessages from '@/mixins/ErrorMessages'
import SuccessMessage from '@/mixins/SuccessMessage'
import {BAlert} from 'bootstrap-vue'
export default {
  name: 'weekTemplate',
  mixins: [ErrorMessages, SuccessMessage, MomentMixin],
  comments:{
    BAlert,

  },
  data(){
    return{
      data:''
    }
  },

  methods:{
    getTimeString: function (value) {
      return instance.formatDate(value, { skeleton: 'hm' });
    },
  },
  mounted () {
    if (this.data.applications_count !==0){
      this.data.color ='#EFA12C'
    }
  }
}
</script>

<style scoped>
.chell{
  width: 100% !important;

}
.template-wrapper{
  padding: 10px;
  height: 100%;
  font-size: 12px;

}

.template-wrapper-cancel {
  padding: 10px;
  height: 100%;
  font-size: 12px;
  color: black;

}
.borderBind{
  border-style: dashed;
  background-color: transparent;
  border-radius: 4px;
  color: black;
  border-color: #4361EE ;
}

.borderBindBooked{
  border-style: dashed;
  background-color: transparent;
  border-radius: 4px;
  color: black;
  border-color: #B2B2B2 ;
}
/* .subject{
} */

.jobRoles{
  margin-top: 5px;
}

.black-badge{
  background-color:black; 
  padding: 5px;
}

.white-template{
  margin-right: 4px;  
  color: rgb(0, 196, 154); 
  background: white;  
  width: fit-content;
}

.white-template-content{
  overflow: hidden; 
  text-overflow: ellipsis; 
  background-color: #FFFFFF; 
  border-radius: 3px; 
  padding: 1px ;
  color: #4361EE;
  width: fit-content;
}

</style>
